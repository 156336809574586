import React from "react";
import { Link, graphql } from "gatsby";
import get from "lodash/get";
import SEO from "../components/seo";
import Layout from "../components/layout";
import Section from "../components/section";
import CarerPreview from "../components/carer-preview";

import pageStyles from "./page.module.scss";
import styles from "./carers.module.scss";

class CarersIndex extends React.Component {
  render() {
    const siteTitle = "Carers";
    const carers = get(this, "props.data.allContentfulCaregiver.edges");

    return (
      <Layout location={this.props.location}>
        <div>
          <SEO title={siteTitle} />{" "}
          <Section>
            <div className={pageStyles.headline}>
              <h2>Meet your local caregivers</h2>{" "}
              <p>
                An independent network running in the Wellington area since 2018
              </p>
            </div>
            <ul className={styles.list}>
              {carers.map(({ node }) => {
                return (
                  <li key={`carers_${node.slug}`}>
                    <CarerPreview className={styles.carer} carer={node} />{" "}
                  </li>
                );
              })}
            </ul>
          </Section>
        </div>{" "}
      </Layout>
    );
  }
}

export default CarersIndex;

export const pageQuery = graphql`
  query CarersIndexQuery {
    site {
      siteMetadata {
        title
      }
    }
    allContentfulCaregiver(
      sort: { fields: [memberSince], order: ASC }
      filter: { status: { eq: "active" } }
    ) {
      edges {
        node {
          fullName
          slug
          memberSince(formatString: "MMM YY")
          memberLocation
          memberRadius
          attributes
          carerImage {
            fluid(
              cropFocus: FACE
              resizingBehavior: FILL
              quality: 100
              maxWidth: 300
              maxHeight: 300
            ) {
              ...GatsbyContentfulFluid
            }
          }
          about {
            about
          }
          status
        }
      }
    }
  }
`;
